import React, { FunctionComponent } from "react";
import { Seo } from "../components/helmets/Seo";
import { KnowledgeLayout } from "../components/knowledges/KnowledgeLayout";
import { RecentlyUpdates } from "../components/knowledges/RecentlyUpdates";
import { ShareBelt } from "../components/social/ShareBelt";
import { Box } from "@material-ui/core";

const Knowledges: FunctionComponent = () =>{
  const description: string = "HassakuLabの技術メモ";
  return (
    <KnowledgeLayout categories={[]}>
      <Seo
        image="/images/knowledge.png"
        title="Knowledges"
        description={description} />
      <RecentlyUpdates />
      <Box mt={4}>
        <ShareBelt
          title="Knowledges"
          description={description}
          path="/knowledges/"
        />
      </Box>
    </KnowledgeLayout>
  )
}

export default Knowledges;