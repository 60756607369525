import React, { FunctionComponent } from "react";
import { Box, Link, Typography, Grid, Chip } from "@material-ui/core";
import styled from "styled-components";
import { AppColor } from "../../styles/AppColor";

const StyledLink = styled(Link)`
  text-decoration: none;
`;

const TitleText = styled((props) =>(
  <Typography {...props} />
))`
  font-size: 1.1rem;
  font-weight: bold;
  color: ${AppColor.h1Color};
`;

const NavigationText = styled(Typography)`
  &:focus, &:hover, &:visited, &:link, &:active {
      text-decoration: none;
  }
`;

const StyledBox = styled(Box)`
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-bottom-color: ${props => props.theme.palette.secondary.main};


  &:hover{
    background-color: ${AppColor.background};
  }
`

const StyledChip = styled(Chip)`
  padding-left: 4px;
  padding-right: 4px;
  text-decoration: none;
`;

type Property ={
  title: string,
  keywords: string[],
  date: string,
  slug: string,
  category: string,
}

export const RecenlyItem: FunctionComponent<Property> = (props) =>(
  <StyledLink href={`/knowledges` + props.slug} color="inherit">
    <StyledBox p={3}>
      <Typography variant="body2">
        {props.date}
      </Typography>
      <NavigationText>
        {props.category.replace("csharp", "C#")}
      </NavigationText>
      <TitleText component="span">
        {props.title}
      </TitleText>
      <Box mt={1}>
        <Grid container direction="row" alignItems="center" spacing={1}>
          {props.keywords.map(word =>(
            <Grid item key={word}>
              <StyledChip variant="outlined" size="small" label={word}/>
            </Grid>
          ))}
        </Grid>
      </Box>
    </StyledBox>
  </StyledLink>
);