import React, { FunctionComponent, Fragment } from "react";
import { useStaticQuery, graphql } from "gatsby";
import { Grid, Typography, Box } from "@material-ui/core";
import { RecenlyItem } from "./RecentlyItem";
import styled from "styled-components";

const StyledBox = styled(Box)`
  border-top-style: solid;
  border-top-width: 1px;
  border-top-color: ${props => props.theme.palette.secondary.main};
`

export const RecentlyUpdates: FunctionComponent = () =>{
  const data = useStaticQuery(graphql`
    {
      allFile(filter: {extension: {eq: "md"}, sourceInstanceName: {eq: "knowledges"}}, sort: {fields: childMarkdownRemark___frontmatter___date, order: DESC}, limit: 10) {
        edges {
          node {
            childMarkdownRemark {
              fields {
                slug
              }
              frontmatter {
                title
                category
                date
                keywords
              }
            }
          }
        }
      }
    }
  `);

  const remarks = data.allFile.edges.map(edge => edge.node.childMarkdownRemark);

  return (
    <Fragment>
      <Typography component="span">Recently Updated</Typography>
      <StyledBox>
        <Grid container direction="column">
          {remarks.map(remark =>(
            <Grid item key={remark.fields.slug}>
              <RecenlyItem
                date={remark.frontmatter.date}
                keywords={remark.frontmatter.keywords}
                category={remark.frontmatter.category}
                slug={remark.fields.slug}
                title={remark.frontmatter.title}
              />
            </Grid>
          ))}
        </Grid>
      </StyledBox>
    </Fragment>
  );
}